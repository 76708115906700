<template>
    <Introduction :class="'background-paris'">
        <template #title>
            Париж - город, который говорит сам за себя.
        </template>
    </Introduction>

    <Main>
        <template #current>
            Париж
        </template>

        <template #youtube>
            <iframe src="https://www.youtube.com/embed/2entb9r3q1Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>

        <template #pricing>
            <div class="case">
                <div class="title">
                    <h5>РЕСТОРАНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Стоимость обеда/ужина в недорогом ресторане на 1 человека - 15 евро</li>
                        <li>Стоимость обеда/ужина на двоих в ресторане среднего уровня (обед/ужин из 3-х блюд) - 60 евро</li>
                        <li>McMeal в Макдональдс - 9 евро</li>
                        <li>Капучино - 3,7 евро</li>
                        <li>Местное пиво - 7 (0,5 литра)</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>МАГАЗИНЫ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Молоко (1 литр) - 1,12 евро</li>
                        <li>Буханка белого хлеба (500 гр) - 1,96 евро</li>
                        <li>Рис (1 кг) - 1,56 евро</li>
                        <li>Яйца (12 шт) - 2,03 евро</li>
                        <li>Местный сыр (1 кг) - 17,29 евро</li>
                        <li>Филе курицы (1 кг) - 12,58 евро</li>
                        <li>Яблоки (1 кг) - 2,73 евро</li>
                        <li>Картофель (1 кг) - 2,01 евро</li>
                        <li>Бутылка вина (средний уровень) - 8 евро</li>
                        <li>Местное пиво (0,5 литра) - 1,85 евро</li>
                        <li>Вода (1,5 литра) - 0,85 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>ТРАНСПОРТ</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Билет в 1 конец - 2 евро</li>
                        <li>Абонемент на месяц - 75 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Развлечения </h5>
                </div>
                <div class="details">
                    <ul>
                        <li>Фитнес-клуб, абонемент на месяц - 36 евро</li>
                        <li>Билет в кино - 12 евро</li>
                    </ul>
                </div>
            </div>
            <div class="case">
                <div class="title">
                    <h5>Проживание</h5>
                </div>
                <div class="details">
                    <ul>
                        <li>1-комнатные апартаменты в центре города - 1272 евро</li>
                        <li>3-комнатные апартаменты в центре города - 2738 евро</li>
                        <li>1-комнатные апартаменты не в центре города - 895 евро</li>
                        <li>3-комнатные апартаменты не в центре города - 1837 евро</li>
                    </ul>
                </div>
            </div>
        </template>

        <template #intro>
            <p>
                Париж, столица Франции, является крупным европейским городом и мировым центром искусства, моды, гастрономии и культуры. Его городской пейзаж 19 века пересекают широкие бульвары и река Сена. Помимо таких достопримечательностей, как Эйфелева башня и готический собор Нотр-Дам 12-го века, город известен своей культурой кафе и дизайнерскими бутиками на улице Фобур Сент-Оноре. Население города - 2,2 млн человек.
            </p>
            <p>
                Дорогое жилье/высокие зарплаты:
            </p>
            <p>
                При выборе Парижа в качестве места обучения стоит учитывать, что это самый дорогой город Франции. Цены на квартиры в Париже - в среднем 31 евро / м². При сопоставимых характеристиках аренда жилья в Париже на 48,9% дороже, чем в провинциальном эквиваленте, а общая стоимость жилья (включая все сборы) на 24,7% выше. При этом здесь также самые высокие зарплаты, за счет чего такую высокую стоимость жилья удается компенсировать. В среднем, по оценкам, зарплаты парижан на 20-25% выше, чем у их коллег из провинции.
            </p>
            <p>
                Таким образом все больше и больше студентов снимают жилье совместно, чтобы снизить расходы. Такие сайты, как Appartager, специализируются в этой области. Вы также можете проконсультироваться с такими источниками, как LocService и La Carte des Colocs, чтобы получить представление о типах доступных объявлений.
            </p>
            <p>
                Гастрономическая столица:
            </p>
            <p>
                По оценкам, на каждые 235 жителей Парижа приходится один ресторан. Париж привлекает, и поэтому вполне естественно, что здесь много звездных поваров, как французских, так и иностранных.
                Независимо от вашей диеты или кулинарных предпочтений, вы обязательно найдете что-то, что удовлетворит вас, независимо от размера вашего кошелька. Париж поразит ваши вкусовые рецепторы и ваши чувства: от соседнего бистро, где подают вкусную пиццу, до роскошного ресторана с панорамным видом на Эйфелеву башню, а также всевозможных вегетарианских и экзотических ресторанов.   
            </p>
            <p>
                Аперо после работы и вечеринки:
            </p>
            <p>
                Подсчитано, что 20% парижан выходят на улицу вечером развеяться против только 5% жителей пригородов. 40% возвращаются домой сразу после работы по сравнению с 66% жителей Иль-де-Франс.
                Это правда, что среди ресторанов, бистро, ночных баров, выставок, показов и т.д. на улицах Парижа есть чем заняться после наступления темноты.
                Говоря об экспозициях, в среднем, в Париже в любой момент времени их около сотни. Вы сможете тренировать свое художественное чутье.
            </p>

            <p>
                Жить в Париже - значит иметь доступ ко всему:
            </p>

            <p>
                Если Вы ищите путешествий на выходных, помните, что вы в Париже. Ни один другой французский город с его шестью крупными железнодорожными станциями и тремя аэропортами не открывает перед вами столько горизонтов.
                Вы можете сесть на поезд, чтобы увидеть пляжи Нормандии, леса Бретани или горы Савойи. Без особого труда вы можете сесть на самолет или на Eurostar и отправиться в отпуск через Ла-Манш, будь то Англия, Ирландия или Шотландия.
            </p>
        </template>

        <template #logement>
            <li>1-комнатные апартаменты в центре города - 1272 евро</li>
            <li>3-комнатные апартаменты в центре города - 2738 евро</li>
            <p>Районы, которые стоит избегать : 10-й, 19-й, 20-й округи</p>
            <p>Районы, где стоит жить: 1-й, 2-й, 3-й, 4-й, 5-й, 6-й, 7-й, 8-й, 11-й, 14-й, 15-й, 16-й, 17-й округи</p>
        </template>

    </Main>
</template>

<script>
import Introduction from '@/components/city/slots/Introduction.vue'
import Main from '@/components/city/slots/Main.vue'
export default {
    components:{
        Introduction,
        Main
    }
}
</script>