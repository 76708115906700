<template>
    <div class="city">
        <Navbar/>
        <Paris/>
    </div>
</template>
<script>
import Navbar from '@/components/common/Navbar.vue'
import Paris from '@/components/city/Paris.vue'
export default {
    components: {
        Navbar,
        Paris
    }
}
</script>